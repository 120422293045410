<template>
  <div class="noPrint">
    <div
      class="floating-menu btn-theme"
      :class="{ open: isExpanded }"
      @click="toggleMenu"
    >
      <div class="main-icon icon m-0">
        <i class="fas fa-lg fa-plus"></i>
      </div>
    </div>
    <div class="expanded-options" :class="{ expanded: isExpanded }" @click.stop>
      <div
        class="option my-2"
        @click="openJumpToModal"
        v-tooltip.left="isMobileTablet ? '' : 'Jump To'"
        v-if="user && !user.isPatient"
      >
        <div class="icon" style="background-color: black; color: white">
          <i class="fa fa-lg fa-search"></i>
        </div>
      </div>

      <div class="option my-2" @click="addCalendarEvent">
        <div
          class="icon"
          style="background-color: darkred; color: white"
          v-tooltip.left="
            isMobileTablet
              ? ''
              : !user || user.isPatient
              ? 'View My Events'
              : 'Add Event'
          "
        >
          <i class="fas fa-lg fa-calendar-plus"></i>
        </div>
      </div>
      <div
        class="option my-2"
        @click="openAddTask"
        v-tooltip.left="isMobileTablet ? '' : 'Add Task'"
      >
        <div class="icon" style="background-color: darkcyan; color: white">
          <i class="fa fa-lg fa-edit"></i>
        </div>
      </div>
      <div
        class="option my-2"
        @click="optionClicked('AI')"
        v-if="clickedOption != 'AI' && isAllowed"
      >
        <div
          class="icon"
          style="background-color: darkgrey"
          v-tooltip.left="isMobileTablet ? '' : 'Oliver AI'"
        >
          <img :src="bubbleImg" :alt="`AI`" />
        </div>
      </div>
    </div>
    <div class="selected-option-container" v-if="clickedOption">
      <div
        class="
          close-selected
          btn-secondary
          d-flex
          align-items-center
          justify-content-center
        "
        v-if="clickedOption"
        @click="closeSelectedOption()"
      >
        <i class="fas fa-sm fa-times"></i>
      </div>
      <OliverChatBot v-if="clickedOption == 'AI'" ref="AI" />
    </div>
    <modal
      class="jump-to-floating-modal"
      name="jump-to-floating-modal"
      transition="pop-out"
      :draggable="!isMobileTablet"
      :resizable="true"
      :height="390"
    >
      <div class="position-relative">
        <div class="vm--top-right-slot" style="z-index: 1">
          <div class="d-flex w-100 justify-content-between align-items-center">
            <span class="h3 mb-0 ml-3 mt-3">Search for user</span>
            <button
              class="btn p-0 pr-3 pt-3 border-0"
              @click="closeJumpToModal"
            >
              <i class="fas fa-times fa-lg"></i>
            </button>
          </div>
        </div>
        <div class="card-body">
          <input
            type="text"
            class="form-control"
            placeholder="Jump To..."
            v-model="searchKey"
          />
          <hr />
          <ul class="search-result_users border">
            <li
              class="text-center p-3 h3 m-0"
              v-if="!loading && !filteredUsers.length && searchKey"
            >
              No users found.
            </li>
            <li
              class="text-center p-3 h3 m-0"
              v-if="!loading && !filteredUsers.length && !searchKey"
            >
              No users to search for.
            </li>
            <alert v-if="loading" class="m-0" />
            <li
              v-for="userItem in filteredUsers"
              :key="userItem.username"
              @click="navigateToUser(userItem)"
              class="pointer-cursor"
            >
              <div
                class="
                  d-flex
                  justify-content-between
                  jump-list-item
                  align-items-center
                "
              >
                <div
                  class="jump-list-name"
                  :class="`${
                    userItem.status != 'INACTIVE' ? '' : 'text-danger'
                  }`"
                >
                  {{ userItem.name }}
                </div>
                <div
                  class="jump-list-role text-bold text-primary"
                  :class="`${
                    userItem.status != 'INACTIVE'
                      ? 'text-primary'
                      : 'text-danger'
                  }`"
                >
                  {{ getRoleName(parseInt(userItem.roleId)) }}
                </div>
                <div
                  class="delete-user-icon text-danger"
                  v-if="
                    user &&
                    user.isAdmin &&
                    user.email == 'eng.karam.eqhash@gmail.com'
                  "
                  @click.stop="deleteUser(userItem.user_id)"
                >
                  <i class="fa fa-trash" />
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </modal>
    <sidebar
      :toggle="createTaskModal"
      @close="closeCreateTaskModal"
      class="create-task-modal"
    >
      <Create
        @saved="taskSaved"
        :noRedirect="true"
        :insideModal="true"
        :defaultTaskType="!user || user.isPatient ? 7 : -1"
        :assignee="!user || user.isPatient ? providerUserIds : []"
        v-if="createTaskModal"
      ></Create>
    </sidebar>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { default as Create } from "../views/tasks/Create";
import OliverChatBot from "./OliverBot/OliverChatBot.vue";

export default {
  components: {
    Create,
    OliverChatBot,
  },
  data() {
    return {
      isExpanded: false,
      clickedOption: false,
      createTaskModal: false,
      searchKey: "",
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      allUsers: (state) => state.security.usersWithRoles,
      companyData: (state) => state.settings.companyProfile,
      loading: (state) => state.security.usersWithRolesLoading,
      patientProviders: (state) => state.patients.providers.data,
    }),
    providerUserIds: function () {
      if (this.patientProviders.length) {
        return this.patientProviders.map((item) => item.user_id);
      }
      return [];
    },
    isMobileTablet: function () {
      return window.innerWidth <= 992;
    },
    isAllowed: function () {
      if (this.user && (this.user.isAdmin || this.user.isManagingAdmin)) {
        return true;
      }
      return false;
    },
    bubbleImg: function () {
      return (
        this.companyData.bot_logo ||
        this.companyData.logo ||
        require("@/assets/img/icons/open-ai-default.svg")
      );
    },
    filteredUsers: function () {
      if (!this.searchKey) {
        return this.allUsers;
      }
      return (this.allUsers || []).filter(
        (item) =>
          item.name.toLowerCase().indexOf(this.searchKey.toLowerCase()) > -1
      );
    },
  },
  methods: {
    ...mapActions({
      getUserInfo: "auth/getUserInfo",
      getPatientProviders: "patients/providers/getBasicProviders",
    }),
    toggleMenu: function () {
      this.isExpanded = !this.isExpanded;
    },
    closeMenu: function () {
      this.isExpanded = false;
    },
    optionClicked: function (key) {
      this.clickedOption = key;
      this.$forceUpdate();
      if (key == "AI") {
        setTimeout(() => {
          this.isExpanded = false;
        }, 0);
      }
    },
    openAddTask: function () {
      if (!this.user || this.user.isPatient) {
        if (this.providerUserIds.length) {
          this.createTaskModal = true;
          return;
        } else {
          return this.getUserInfo().then((res) => {
            this.getPatientProviders(res.id).then(() => {
              this.createTaskModal = true;
            });
          });
        }
      }
      this.createTaskModal = true;
    },
    addCalendarEvent: function () {
      if (this.user) {
        if (this.user.isAdmin || this.user.isManagingAdmin) {
          this.$router.push({ path: "/admin/profile?event=true" });
        } else if (this.user.isProvider) {
          this.$router.push({
            path: `/providers/${this.user.provider_id}?event=true`,
          });
        } else if (this.user.isPatient) {
          this.$router.push({
            path: `/clients/${this.user.patient_id}?event=true`,
          });
        }
      }
    },
    openJumpToModal: function () {
      this.$modal.show("jump-to-floating-modal");
    },
    closeJumpToModal: function () {
      this.$modal.hide("jump-to-floating-modal");
    },
    closeSelectedOption: function () {
      this.clickedOption = false;
      this.$forceUpdate();
    },
    getRoleName: function (roleId) {
      let roleName;
      switch (roleId) {
        case 1:
          roleName = "(Admin)";
          break;
        case 2:
          roleName = "(Provider)";
          break;
        case 5:
          roleName = "(Manager)";
          break;
        default:
          roleName = "(Client)";
          break;
      }
      return roleName;
    },
    deleteUser: function (id) {
      this.$store.dispatch("Profile/deleteUser", id);
    },
    navigateToUser: function (userItem) {
      this.closeJumpToModal();
      if (userItem.roleId == 1 || userItem.roleId == 5) {
        this.$router.push({ path: "/loading" });
        setTimeout(() => {
          this.$router.replace({ path: `/admin/users/${userItem.id}` });
        }, 500);
      } else if (userItem.roleId == 2) {
        this.$router.push({ path: "/loading" });
        setTimeout(() => {
          this.$router.replace({ path: `/providers/${userItem.id}` });
        }, 500);
      } else {
        this.$router.push({ path: "/loading" });
        setTimeout(() => {
          this.$router.replace({ path: `/clients/${userItem.id}` });
        }, 500);
      }
    },
    closeCreateTaskModal: function () {
      this.createTaskModal = false;
      window.localStorage.removeItem("task_form");
    },
    taskSaved: function () {
      this.createTaskModal = false;
      window.localStorage.removeItem("task_form");
    },
  },
};
</script>

<style scoped lang="scss">
.jump-to-floating-modal {
  z-index: 1002;
}
.close-selected {
  position: fixed;
  right: 84px;
  bottom: 100px;
  z-index: 1001;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  opacity: 1;
  cursor: pointer;
}
.floating-menu {
  position: fixed;
  bottom: 60px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  transition: all 0.3s ease;
}

.expanded-options {
  color: #333333;
  position: fixed;
  bottom: 114px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px;
  z-index: -1;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;

  &.expanded {
    z-index: 100;
  }
}

.option {
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 50%;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
}

.icon {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }
}

.main-icon {
  transition: transform 0.3s ease;

  .floating-menu.open & {
    transform: rotate(45deg);
  }
}

.floating-menu.open,
.floating-menu.open:hover {
  filter: brightness(0.95);
}

.expanded {
  opacity: 1;
  transform: translateY(0);
}

.search-result_users {
  padding: 0;
  max-height: 240px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  margin-top: 8px;
  list-style: none;
  .jump-list-item {
    padding: 6px 12px;
    transition: background-color 0.2s ease;
    .jump-list-role {
      letter-spacing: 0.5px;
    }
    &:hover {
      background-color: #d2ddec;
    }
  }
}
</style>
